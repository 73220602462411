<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Dashboard</div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>
      <div>
        <div class="outer" style="height: 320px; margin-top: 50px">
          <NCard
            style="margin-top: 20px; margin-left: 30px; border: 0px; min-width: 800px"
          >
            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 150px; font-weight: bold">회원</div>
            </n-space>
          </NCard>
          <div style="display: flex; justify-content: right">
            <p>총 회원 가입 {{ totalJoin }} / 탈퇴 {{ totalWd }}</p>
          </div>
          <n-data-table
            class="inner"
            style="margin-left: 100px; width: 1400px; min-width: 1400px"
            ref="table"
            :columns="columns"
            :data="showList"
            striped
          />
        </div>

        <div class="outer" style="height: 320px; margin-top: 50px">
          <NCard
            style="margin-top: 20px; margin-left: 30px; border: 0px; min-width: 800px"
          >
            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 150px; font-weight: bold">알람</div>
            </n-space>
          </NCard>

          <n-data-table
            class="inner"
            style="margin-left: 100px; width: 1400px; min-width: 1400px"
            ref="table"
            :columns="columns"
            :data="alarmShowList"
            striped
          />
        </div>

        <NCard style="margin-top: 50px; margin-left: 80px; border: 0px; min-width: 200px">
          <n-space item-style="display: flex" align="center" justify="space-between">
            <n-space item-style="display: flex;" align="center">
              <NCard style="min-width: 150px" :bordered="false">
                <n-select
                  v-model:value="statsPeriodSelected"
                  :options="dailyMonthlyCategory"
                  @update:value="handleUpdateDailyMonthly"
                />
              </NCard>

              <n-date-picker
                v-model:value="startDate"
                :type="datePickerType"
                :is-date-disabled="afterTodayDisabled"
                :update-value-on-close="datePickerType !== 'year'"
              />
              <p>~</p>
              <n-date-picker
                v-model:value="endDate"
                :type="datePickerType"
                :is-date-disabled="expiryDateDisabled"
                :update-value-on-close="datePickerType !== 'year'"
              />

              <NButton
                style="height: 30px; border: none"
                type="success"
                @click="fetchLineData"
              >
                조회
              </NButton>
            </n-space>
            <NCard style="min-width: 200px" :bordered="false">
              <n-select
                v-model:value="lineChartSelected"
                placeholder="Select Category"
                :options="lineChartCategory"
                @update:value="handleUpdateLineChart"
              />
            </NCard>
          </n-space>
        </NCard>

        <div
          class="outer"
          style="height: 420px; margin-top: 50px; margin-left: 100px"
          v-if="lineChartDataAll.length > 1"
        >
          <GChart
            :type="lineChartSelected === 5 ? 'ColumnChart' : 'LineChart'"
            :data="lineChartDataShow"
            :options="chartOptions"
          />
        </div>
        <div
          class="outer"
          style="height: 420px; width: 1420px; margin-top: 50px; margin-left: 100px"
          v-if="lineChartDataAll.length == 1"
        >
          <p>데이터가 없습니다</p>
        </div>
        <div
          class="outer"
          style="height: 420px; width: 1420px; margin-top: 50px; margin-left: 100px"
          v-if="lineChartDataAll.length == 0"
        >
          <p>조회를 눌러주세요</p>
        </div>
      </div>
    </div>
    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  NDataTable,
  NSpace,
  NCard,
  NSelect,
  NDatePicker,
  NButton,
  NModal,
} from "naive-ui";
import { GChart } from "vue-google-charts";
import { getCallable } from "@/config/env";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
const getDailyStatistics = getCallable("getDailyStatistics");
const getMonthlyStatistics = getCallable("getMonthlyStatistics");
const getYearlyStatistics = getCallable("getYearlyStatistics");

export default defineComponent({
  name: "DashBoard",
  components: {
    NDataTable,
    NSpace,
    NCard,
    NSelect,
    GChart,
    NDatePicker,
    NButton,
    NModal,
  },
  data() {
    return {
      isDaily: true,
      startDate: 1183135260000,
      endDate: 1183135260000,
      lineChartSelected: 2, //lineChartCategory 참조
      statsPeriodSelected: 1, //1 : daily, 2: monthly, 3: yearly
      datePickerType: "date",
      baseList: [] as any,
      alarmBaseList: [] as any,
      showList: [] as any,
      alarmShowList: [] as any,
      isAlert: false as boolean,
      alertType: "",
      alertMessage: "",
      totalJoin: 0,
      totalWd: 0,
      dailyMonthlyCategory: [
        {
          label: "일별",
          value: 1,
        },
        {
          label: "월별",
          value: 2,
        },
        {
          label: "연별",
          value: 3,
        },
      ],
      lineChartCategory: [
        {
          label: "누적 회원수",
          value: 2,
        },
        {
          label: "누적 탈퇴 회원수",
          value: 3,
        },
        {
          label: "구독자 수",
          value: 4,
        },
        {
          label: "알람",
          value: 5,
        },
      ],
      columns: [
        {
          title: "연령",
          key: "category",
        },
        {
          title: "10대 미만",
          key: "u10",
        },
        {
          title: "10대",
          key: "ten",
        },
        {
          title: "20대",
          key: "twenty",
        },
        {
          title: "30대",
          key: "thirty",
        },
        {
          title: "40대",
          key: "forty",
        },
        {
          title: "50대",
          key: "fifty",
        },
        {
          title: "미확인",
          key: "unidentified",
        },
        {
          title: "계",
          key: "total",
        },
      ],
      lineChartColumn: [
        "SK",
        "parsedDate",
        "누적 회원수",
        "누적 탈퇴 회원수",
        "구독자 수",

        "어제 울린 알람 수",
        "활성화된 알람 수 (구독)",
        "활성화된 알람 수 (미구독)",
        "활성화된 알람을 설정한 사용자 수 (구독)",
        "활성화된 알람을 설정한 사용자 수 (미구독)",
      ],

      lineChartDataAll: [] as any,
      lineChartDataAllMonth: [] as any,
      lineChartDataShow: [] as any,
      chartOptions: {
        width: 1420,
        height: 500,
        vAxis: { gridlines: { multiple: 1 } },
        annotations: {},
      },
    };
  },
  async mounted() {
    this.getYtdDate();
    this.getStatistics();
  },
  methods: {
    updateChartOptions(selected: any) {
      console.log(selected);
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    expiryDateDisabled(ts: number) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const date = new Date(ts);
      const startDate = new Date(this.startDate);
      startDate.setHours(0, 0, 0, 0);

      return date < startDate || date > today;
    },

    afterTodayDisabled(ts: number) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const date = new Date(ts);
      return date > today;
    },
    getYtdDate() {
      let yesterday = new Date();

      yesterday.setDate(yesterday.getDate() - 1);
      let timeStamp = yesterday.getTime();
      this.startDate = timeStamp;
      this.endDate = timeStamp;
    },

    convertLineChartData(sortedData: any) {
      this.lineChartDataShow = sortedData.map((row: any) => {
        if (this.lineChartSelected === 5) {
          return [
            row[0],
            row[5] ?? 0,
            row[6] ?? 0,
            row[7] ?? 0,
            row[8] ?? 0,
            row[9] ?? 0,
          ];
        } else {
          return [
            row[0],
            row[this.lineChartSelected === undefined ? 2 : this.lineChartSelected],
          ];
        }
      });

      if (this.lineChartSelected === 5) {
        let data = this.lineChartDataShow;
        let transformedData = [
          [
            data[0][0],
            ...data[0].slice(1).flatMap((col: any) => [col, { role: "annotation" }]),
          ],
        ];

        transformedData.push(
          ...data
            .slice(1)
            .map((row: any) => [
              row[0],
              ...row.slice(1).flatMap((value: any) => [value, value.toString()]),
            ])
        );

        this.lineChartDataShow = transformedData;
      }
    },

    handleUpdateLineChart(value: string, option: any) {
      this.updateChartOptions(value);
      this.convertLineChartData(this.lineChartDataAll);
    },
    handleUpdateDailyMonthly(value: any, option: any) {
      console.log(value);
      if (value == 1) {
        this.datePickerType = "date";
      } else if (value == 2) {
        this.datePickerType = "month";
      } else {
        this.datePickerType = "year";
      }
      this.getYtdDate();
    },
    fetchLineData() {
      if (this.lineChartSelected == undefined) {
        this.alertMessage = "Category를 선택해주세요";
        this.alertType = "error";
        this.isAlert = true;
        return;
      }

      if (this.statsPeriodSelected == 1) {
        this.getDailyStatistics();
      } else if (this.statsPeriodSelected == 2) {
        this.getMonthlyStatistics();
      } else if (this.statsPeriodSelected == 3) {
        this.getYearlyStatistics();
      }
    },

    getStatistics() {
      this.baseList = [];
      this.alarmBaseList = [];
      this.showList = [];
      this.alarmShowList = [];
      let yesterday = new Date();

      yesterday.setDate(yesterday.getDate() - 1);

      let timeStamp = yesterday.getTime();
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        let data = {
          startYYYYMMDD: common.getYYYYMMDD(timeStamp),
          endYYYYMMDD: common.getYYYYMMDD(timeStamp),
        } as any;
        getDailyStatistics(data)
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              let i = 0;

              for (let item of result.data.data.Items) {
                this.totalJoin = item.join ?? 0;
                this.totalWd = item.withDraw ?? 0;
                for (const [key, value] of Object.entries(item)) {
                  let row = {} as any;
                  this.getUserDataObject(key, value, row);

                  if (row.category != undefined) {
                    this.baseList.push(row);
                  }

                  let alarmRow = {} as any;
                  this.getAlarmDataObject(key, value, alarmRow);
                  if (alarmRow.category != undefined) {
                    this.alarmBaseList.push(alarmRow);
                  }
                }
                this.baseList.sort((a: any, b: any) => a.index - b.index);
                this.alarmBaseList.sort((a: any, b: any) => a.index - b.index);
              }
              this.showList = this.baseList;
              this.alarmShowList = this.alarmBaseList;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    getUserDataObject(key: any, data: any, newData: any) {
      if (key == constant.statisticsKey.SUBSCRIBE) {
        newData.index = 1;
        newData.category = "구독자 수";
      } else if (key == constant.statisticsKey.MEMBER) {
        newData.index = 0;
        newData.category = "회원 수";
      } else if (key == constant.statisticsKey.CUSTOM_VOICE) {
        newData.index = 2;
        newData.category = "내 커스텀보이스 보유";
      } else if (key == constant.statisticsKey.GIFT_CUSTOM_VOICE) {
        newData.index = 3;
        newData.category = "선물 받은 커스텀보이스 보유 회원";
      }

      for (const [key, value] of Object.entries(data)) {
        let ageKeyArr = key.split("_");
        let ageKeyLast = ageKeyArr[ageKeyArr.length - 1];
        if (ageKeyLast == "all") {
          newData.total = value;
        } else if (ageKeyLast == "10u") {
          newData.u10 = value;
        } else if (ageKeyLast == "10") {
          newData.ten = value;
        } else if (ageKeyLast == "20") {
          newData.twenty = value;
        } else if (ageKeyLast == "30") {
          newData.thirty = value;
        } else if (ageKeyLast == "40") {
          newData.forty = value;
        } else if (ageKeyLast == "50o") {
          newData.fifty = value;
        } else if (ageKeyLast == "N") {
          newData.unidentified = value;
        }
      }
    },
    getAlarmDataObject(key: any, data: any, newData: any) {
      if (key == constant.statisticsKey.PLAY_ALARM) {
        newData.index = 0;
        newData.category = "어제 울린 알람 수";
      } else if (key == constant.statisticsKey.SUBS_ALARM_ON) {
        newData.index = 1;
        newData.category = "활성화된 알람 수 (구독)";
      } else if (key == constant.statisticsKey.NOT_SUBS_ALARM_ON) {
        newData.index = 2;
        newData.category = "활성화된 알람 수 (미구독)";
      } else if (key == constant.statisticsKey.SUBS_ON_ALARM_USER) {
        newData.index = 3;
        newData.category = "활성화된 알람을 설정한 사용자 수 (구독)";
      } else if (key == constant.statisticsKey.NOT_SUBS_ON_ALARM_USER) {
        newData.index = 4;
        newData.category = "활성화된 알람을 설정한 사용자 수 (미구독)";
      }

      for (const [key, value] of Object.entries(data)) {
        let ageKeyArr = key.split("_");
        let ageKeyLast = ageKeyArr[ageKeyArr.length - 1];
        if (ageKeyLast == "all") {
          newData.total = value;
        } else if (ageKeyLast == "10u") {
          newData.u10 = value;
        } else if (ageKeyLast == "10") {
          newData.ten = value;
        } else if (ageKeyLast == "20") {
          newData.twenty = value;
        } else if (ageKeyLast == "30") {
          newData.thirty = value;
        } else if (ageKeyLast == "40") {
          newData.forty = value;
        } else if (ageKeyLast == "50o") {
          newData.fifty = value;
        } else if (ageKeyLast == "N") {
          newData.unidentified = value;
        }
      }
    },
    getDailyStatistics() {
      this.lineChartDataAll = [];
      this.lineChartDataAll.push(this.lineChartColumn);
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        let data = {
          startYYYYMMDD: common.getYYYYMMDD(this.startDate),
          endYYYYMMDD: common.getYYYYMMDD(this.endDate),
        } as any;
        getDailyStatistics(data)
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              for (let item of result.data.data.Items) {
                let idSkArr = item.SK.split("#");
                let YYYYMMDD = idSkArr[idSkArr.length - 1];
                var year = YYYYMMDD.substring(0, 4);
                var month = YYYYMMDD.substring(4, 6);
                var day = YYYYMMDD.substring(6, 8);

                const formattedDate = YYYYMMDD;
                var parseDate = new Date(year, month - 1, day);
                let member = item.member?.member_all ?? 0;
                let withdraw = item.withDraw ?? 0;
                let subscribe = item.subscribe?.subscribe_all ?? 0;
                let alarmPlay = item.playAlarm?.playAlarm_all ?? 0;
                let subsOnAlarm = item.subscribeOnAlarm?.subscribeOnAlarm_all ?? 0;
                let notSubsOnAlarm =
                  item.notSubscribeOnAlarm?.notSubscribeOnAlarm_all ?? 0;
                let subsOnAlarmUser =
                  item.subscribeOnAlarmUser?.subscribeOnAlarmUser_all ?? 0;
                let notSubsOnAlarmUser =
                  item.notSubscribeOnAlarmUser?.notSubscribeOnAlarmUser_all ?? 0;

                this.lineChartDataAll.push([
                  formattedDate,
                  parseDate,
                  member,
                  withdraw,
                  subscribe,
                  alarmPlay,
                  subsOnAlarm,
                  notSubsOnAlarm,
                  subsOnAlarmUser,
                  notSubsOnAlarmUser,
                ]);
              }
              const header = this.lineChartDataAll[0];
              let rows = this.lineChartDataAll.slice(1);
              rows.sort((a: any, b: any) => {
                return a[1] - b[1];
              });
              let sortedData = [header, ...rows];
              this.convertLineChartData(sortedData);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    getMonthlyStatistics() {
      this.lineChartDataAll = [];
      this.lineChartDataAll.push(this.lineChartColumn);
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        let data = {
          startYYYYMM: common.getYYYYMM(this.startDate),
          endYYYYMM: common.getYYYYMM(this.endDate),
        } as any;
        getMonthlyStatistics(data)
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              for (let item of result.data.data.Items) {
                let formattedDate = item.SK.split("#")[2];
                let parseDate = parseInt(item.createdDate);
                let member = item.member?.member_all ?? 0;
                let withdraw = item.withDraw ?? 0;
                let subscribe = item.subscribe?.subscribe_all ?? 0;
                let alarmPlay = item.playAlarm?.playAlarm_all ?? 0;
                let subsOnAlarm = item.subscribeOnAlarm?.subscribeOnAlarm_all ?? 0;
                let notSubsOnAlarm =
                  item.notSubscribeOnAlarm?.notSubscribeOnAlarm_all ?? 0;
                let subsOnAlarmUser =
                  item.subscribeOnAlarmUser?.subscribeOnAlarmUser_all ?? 0;
                let notSubsOnAlarmUser =
                  item.notSubscribeOnAlarmUser?.notSubscribeOnAlarmUser_all ?? 0;
                this.lineChartDataAll.push([
                  formattedDate,
                  parseDate,
                  member,
                  withdraw,
                  subscribe,
                  alarmPlay,
                  subsOnAlarm,
                  notSubsOnAlarm,
                  subsOnAlarmUser,
                  notSubsOnAlarmUser,
                ]);
              }

              const header = this.lineChartDataAll[0];
              let rows = this.lineChartDataAll.slice(1);
              rows.sort((a: any, b: any) => {
                return a[1] - b[1];
              });
              let sortedData = [header, ...rows];

              this.convertLineChartData(sortedData);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    getYearlyStatistics() {
      this.lineChartDataAll = [];
      this.lineChartDataAll.push(this.lineChartColumn);
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        let data = {
          startYYYY: common.getYYYY(this.startDate),
          endYYYY: common.getYYYY(this.endDate),
        } as any;
        getYearlyStatistics(data)
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              for (let item of result.data.data.Items) {
                let formattedDate = item.SK.split("#")[2];
                let parseDate = parseInt(item.createdDate);
                let member = item.member?.member_all ?? 0;
                let withdraw = item.withDraw ?? 0;
                let subscribe = item.subscribe?.subscribe_all ?? 0;
                let alarmPlay = item.playAlarm?.playAlarm_all ?? 0;
                let subsOnAlarm = item.subscribeOnAlarm?.subscribeOnAlarm_all ?? 0;
                let notSubsOnAlarm =
                  item.notSubscribeOnAlarm?.notSubscribeOnAlarm_all ?? 0;
                let subsOnAlarmUser =
                  item.subscribeOnAlarmUser?.subscribeOnAlarmUser_all ?? 0;
                let notSubsOnAlarmUser =
                  item.notSubscribeOnAlarmUser?.notSubscribeOnAlarmUser_all ?? 0;

                this.lineChartDataAll.push([
                  formattedDate,
                  parseDate,
                  member,
                  withdraw,
                  subscribe,
                  alarmPlay,
                  subsOnAlarm,
                  notSubsOnAlarm,
                  subsOnAlarmUser,
                  notSubsOnAlarmUser,
                ]);
              }

              const header = this.lineChartDataAll[0];
              let rows = this.lineChartDataAll.slice(1);
              rows.sort((a: any, b: any) => {
                return a[1] - b[1];
              });
              let sortedData = [header, ...rows];

              this.convertLineChartData(sortedData);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
